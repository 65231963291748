import React from 'react'
import * as styles from './features.module.css'
import Typography from '../ui/typography'
import checkmark from './checkmark.svg'
import { GatsbyImage } from 'gatsby-plugin-image'
import Container from '../ui/container'
import BlueArrow from '../../../static/assets/img/arrow-top-right-blue.svg'

const { Paragraph } = Typography

const convertTextToEmail = (textArray) => {
  // Not really beautiful but handle email and bold in text
  var listSpans = []
  for (let i = 0; i < textArray.length; i++) {
    if (textArray[i].children) {
      if (textArray[i].children[0].children) {
        // case strong or email
        if (textArray[i].children[0].tagName == 'strong') {
          listSpans.push(
            <span
              key={textArray[i].children[0].children[0].value.slice(0, 8)}
              className="text-dark font-semibold"
            >
              {textArray[i].children[0].children[0].value}
            </span>
          )
          continue
        }
        // should be email
        if (textArray[i].children[0].tagName == 'em') {
          listSpans.push(
            <span
              key={textArray[i].children[0].children[0].value.slice(0, 8)}
              className={styles.featureLink}
            >
              {textArray[i].children[0].children[0].value}
              {/* TODO: Figure out the arrow image
              
              <button
                type="button"
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
              >
                {arrowTopRight}
              </button> */}
            </span>
          )
          continue
        }
      }
      // case email in text
      if (textArray[i].children.length > 1) {
        if (textArray[i].children[1].tagName == 'strong') {
          listSpans.push(
            <span key={textArray[i].children[0].value.slice(0, 8)} className="">
              {textArray[i].children[0].value}
            </span>
          )
          listSpans.push(
            <span
              key={textArray[i].children[1].children[0].value.slice(0, 8)}
              className="text-dark font-semibold"
            >
              {textArray[i].children[1].children[0].value}
            </span>
          )
          continue
        }
        // should be email
        if (textArray[i].children[1].tagName == 'em') {
          listSpans.push(
            <span
              key={textArray[i].children[0].value.slice(0, 8)}
              className={styles.featureLink}
            >
              {textArray[i].children[0].value}
            </span>
          )
          listSpans.push(
            <span
              key={textArray[i].children[1].children[0].value.slice(0, 8)}
              className={styles.featureLink}
            >
              <a
                href={'mailto:' + textArray[i].children[1].children[0].value}
                className=""
              >
                {textArray[i].children[1].children[0].value}
              </a>
            </span>
          )
          continue
        }
        listSpans.push(
          <span key={textArray[i].children[0].value.slice(0, 8)} className="">
            {textArray[i].children[0].value}
          </span>
        )
        listSpans.push(
          <span key={textArray[i].children[1].value.slice(0, 8)} className="">
            {textArray[i].children[1].value}
          </span>
        )
        continue
      }
      listSpans.push(
        <span key={textArray[i].children[0].value.slice(0, 8)} className="">
          {textArray[i].children[0].value}
        </span>
      )
    }
    // else
    if (textArray[i].value == '\n') {
      listSpans.push(
        <span key={`${i}_n`} className="">
          <br />
          <br />
        </span>
      )
    } else {
      if (textArray[i].value) {
        listSpans.push(
          <span key={`${i}_${textArray[i].value}`} className="">
            {textArray[i].value}
          </span>
        )
      }
    }
    if (textArray[i].value) {
      listSpans.push(
        <span key={`${i}_${textArray[i].value}`} className="">
          {textArray[i].value}
        </span>
      )
    }
  }
  return listSpans
}

export default (props) => {
  function backToTop() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }
  return (
    <Container>
      {props.data.productContentItem.map((f, idx) => (
        <div
          key={idx}
          className={`${
            (idx % 2 == 0 && props.data.imageStartsLeft) ||
            (idx % 2 == 1 && !props.data.imageStartsLeft)
              ? styles.featureImgLeft
              : styles.featureImgRight
          }`}
        >
          <GatsbyImage
            alt={f.image.title} // Should describe image, for now just use title
            className={styles.featureImg}
            imgStyle={{
              objectFit: 'contain',
            }}
            image={f.image.gatsbyImageData}
          />
          <div className={styles.featureContent}>
            {props.data.productContentItem.length >= 1 && (
              <Paragraph className={styles.subtitle}>{f.subtitle}</Paragraph>
            )}
            <h2 className={styles.title}>{f.title}</h2>
            <Paragraph className={styles.content}>
              {convertTextToEmail(
                f.paragraph.childMarkdownRemark.htmlAst.children
              )}
            </Paragraph>
            {f.advantages && f.advantages.length > 0 && (
              <ul className="mt-8 flex flex-col">
                {f.advantages.map((p, j) => (
                  <li key={j} className="mb-4 flex flex-row items-center">
                    <img
                      src={checkmark}
                      className={styles.check}
                      alt="checkmark"
                    />
                    <span className={styles.listContent}>{p}</span>
                  </li>
                ))}
              </ul>
            )}
            {f.moreInfo && (
              <button
                onClick={() => {
                  if (
                    f.moreInfo.url === 'https://www.wagetap.com' ||
                    f.moreInfo.url === 'http://www.wagetap.com'
                  ) {
                    // check if is on another page :
                    if (window && window.location.href.includes('about')) {
                      window.location.href = '/'
                    } else {
                      document.getElementById('phone-input').focus()
                      backToTop()
                    }
                  }
                }}
                className={styles.linkButton}
              >
                <Paragraph className={styles.linkButtonTitle}>
                  {f.moreInfo.label}
                </Paragraph>
                <img
                  alt="Blue arrow pointing up"
                  src={BlueArrow}
                  className={styles.arrowImg}
                />
              </button>
            )}
          </div>
        </div>
      ))}
    </Container>
  )
}
